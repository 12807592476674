(function($)
{
    $(document).ready(function ()
    {
        // ------------------------------------------------------
        // jquery form validator
        // module 'security' additionally loaded via cdn
        // ------------------------------------------------------
        $.validate({
            lang: 'de',
            // modules: 'security, date, logic'
            modules: 'date, logic'
        });

        // ------------------------------------------------------
        // owl / header
        // ------------------------------------------------------
        $(".owl-carousel.owl-header").owlCarousel({
            items: 1,
            autoplay: true,
            dots: true,
            loop: true,
            autoplayHoverPause: true,
            autoplaySpeed: 800,
            autoplayTimeout: 6000,
            nav: true,
            navText: ['', '']
        });

        // ------------------------------------------------------
        // owl / content
        // ------------------------------------------------------
        var owlContent = $('.owl-carousel.owl-content');
        if (owlContent.length > 0) {
            owlContent.each(function () {
                var owl = $(this);
                owl.owlCarousel({
                    loop: true,
                    items: 1,
                    thumbs: true,
                    thumbImage: true,
                    thumbContainerClass: 'owl-thumbs',
                    thumbItemClass: 'owl-thumb-item',
                    dots: true,
                    nav: true,
                    navText: ['', '']
                });

                owl.on('translated.owl.carousel', function(e) {
                    owlShowCaption( owl, e.page.index );
                });
            });
        }

        function owlShowCaption(owl, index) {
            owl.parent().find('.caption').removeClass('in');
            owl.parent().find('.caption-' + index).addClass('in');
        }

        // ------------------------------------------------------
        // to top
        // ------------------------------------------------------
        $('#to-top').click(function () {
            $('html, body').animate({ scrollTop: 0 }, 600);
        });

        // ------------------------------------------------------
        // form for registering for an event
        // ------------------------------------------------------
        var formEventRegister = $('#form-event-register');

        if (formEventRegister.length > 0) {
            var checkGuest1 = $('#check-guest1');
            var checkGuest2 = $('#check-guest2');
            var checkGuest3 = $('#check-guest3');
            var checkGuest = $('.check-guest');

            var fieldsGuest1 = $('#fields-guest1');
            var fieldsGuest2 = $('#fields-guest2');
            var fieldsGuest3 = $('#fields-guest3');

            /**
             * check or uncheck a guest
             */
            checkGuest.click(function onClick ()
            {
                var thisId = $(this).attr('id');

                switch(thisId) {
                    case 'check-guest1':
                        if (checkGuest1.prop('checked') === true) {
                            fieldsGuest1.removeClass('hidden');
                        } else {
                            fieldsGuest1.addClass('hidden');
                        }
                        break;
                    case 'check-guest2':
                        if (checkGuest2.prop('checked') === true) {

                            if (checkGuest1.prop('checked') === true) {
                                fieldsGuest2.removeClass('hidden');
                            } else {
                                checkGuest2.prop('checked', false);
                            }

                        } else {
                            fieldsGuest2.addClass('hidden');
                        }
                        break;
                    case 'check-guest3':
                        if (checkGuest3.prop('checked') === true) {

                            if (checkGuest1.prop('checked') === true && checkGuest2.prop('checked') === true) {
                                fieldsGuest3.removeClass('hidden');
                            } else {
                                checkGuest3.prop('checked', false);
                            }

                        } else {
                            fieldsGuest3.addClass('hidden');
                        }
                        break;
                    default:
                        break;
                }
            });

            $('#date').on('change', function() {
                var dateVal = $(this).val();
                //console.log(dateVal);
                $('#hidden-date-no').val(dateVal);
            });
        }

        // exception for single news entry
        var newsEntries = $('.news-entry');
        /*newsEntries.each(function() {
            var thisNews = $(this);
            if (thisNews.find('.date:contains("16.12.2020")').length > 0) {
                thisNews.find('.link-whole-box').remove();
            }
        });*/

        // add twitter button to pdf links in text
        // polyfill for endsWith for IE
        if (!String.prototype.endsWith) {
            String.prototype.endsWith = function(searchString, position) {
                var subjectString = this.toString();
                if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
                    position = subjectString.length;
                }
                position -= searchString.length;
                var lastIndex = subjectString.indexOf(searchString, position);
                return lastIndex !== -1 && lastIndex === position;
            };
        }

        // ------------------------------------
        // twitter customization
        // ------------------------------------
        var twitterBlock = $('#twitter-block');
        var tweetsShortened = false;
        twitterBlock.delegate('#twitter-widget-0','DOMSubtreeModified propertychange', function() {
            customizeTweetMedia();
            removeChangeCookieConsentLink();
        });

        // TODO needed?
        // setTimeout(function() { customizeTweetDate(); }, 1000);

        /**
         * customize twitter
         */
        function customizeTweetMedia() {
            var twitterContent = twitterBlock.find('.twitter-timeline').contents();

            twitterContent.find('.timeline-Header').hide();
            twitterContent.find('.timeline-Tweet-retweetCredit').hide();
            twitterContent.find('.Avatar').hide();
            twitterContent.find('.timeline-Tweet-media').hide();
            twitterContent.find('.timeline-Tweet-actions').hide();
            twitterContent.find('.timeline-Footer').hide();

            twitterContent.find('.timeline-Widget').css('background-color', 'transparent');
            twitterContent.find('.timeline-Body').css('border', 'none');
            twitterContent.find('.timeline-TweetList-tweet').css({
                'border-top': '2px solid white',
            });
            twitterContent.find('.timeline-Tweet').css({
                'padding': '10px 0 5px 0',
                'background-color': '#e5ebf3',
                'overflow': 'hidden'
            });
            twitterContent.find('.timeline-Tweet-author').css('padding', '0');
            twitterContent.find('.TweetAuthor-nameScreenNameContainer').css({
                'display': 'flex',
                'flex-direction': 'row'
            });
            twitterContent.find('.TweetAuthor-name').css({
                'font-weight': 'normal',
            });
            twitterContent.find('.timeline-Tweet-text').css({
                'font-size': '1.05rem',
                'font-family': '\'Open Sans\',sans-serif',
                'color': '#666',
                'line-height': '1.5',
                'margin': '0 0 0 0'
            });
            twitterContent.find('.timeline-Tweet-metadata').css({
                'margin': '-20px 0 0 0'
            });


             // if (tweetsShortened === false) {
             //    var tweetText = twitterContent.find('.timeline-Tweet-text');
             //    tweetText.each(function() {
             //        var that = $(this);
             //        var thatHtml = that.html();
             //        var parsed = $.parseHTML(thatHtml);
             //        console.log($.parseHTML(thatHtml));
             //        that.html(parsed[0] + parsed[1]+parsed[2]);
             //
             //        if (typeof thatHtml !== 'undefined') {
             //            // that.html(parsed[0] + parsed[1]+parsed[2]);
             //
             //            // console.log(parsed.substring(0, 300));
             //        }
             //
             //        tweetsShortened = true;
             //    });
             // }


            // twitterContent.find('.timeline-TweetList').bind('DOMSubtreeModified propertychange', function() {
            //     customizeTweetMedia(this);
            // });
        }

        function removeChangeCookieConsentLink() {
            $('#twitter-block').find('.change-cookie-consent').remove();
        }

        /**
         * customize date delayed because of too many function calls otherwise
         */
        function customizeTweetDate() {
            var twitterContent = twitterBlock.find('.twitter-timeline').contents();
            twitterContent.find('.timeline-Tweet').each(function () {
                var thisTweet = $(this);
                var date = thisTweet.find('.dt-updated');
                thisTweet.find('.TweetAuthor-screenName').append(date.html());
                date.remove();
            });
        }

        // ------------------------------------
        // NAV fixed links
        // ------------------------------------
        var fixedLinks = $('.js-fixed-link');
        var fixedLinkPopups = $('.js-fixed-link-popup');

        /**
         * click on fixed link navi 
         */
        fixedLinks.click(function (e) {
            var thisLink = $(this);
            fixedLinks.parent().removeClass('active');
            thisLink.parent().addClass('active');
            if (thisLink.hasClass('js-fixed-link-events')) {
                // events
                e.preventDefault();
                fixedLinkPopups.removeClass('in');
                $('.js-fixed-link-popup-events').addClass('in');
            } else {
                // contact
                e.preventDefault();
                fixedLinkPopups.removeClass('in');
                $('.js-fixed-link-popup-contact').addClass('in');
            }
        });

        function closeFixedNavPopups() {
            fixedLinkPopups.removeClass('in');
            fixedLinks.parent().removeClass('active');
        }

        /**
         * click close button of fixed-link popups
         */
        fixedLinkPopups.find('.close').click(function () {
            closeFixedNavPopups();
        });

        $(window).scroll(function () {
            if ($(window).scrollTop() > 100) {
                $('header').addClass('small');
            } else {
                $('header').removeClass('small');
            }
        });

        $('.change-cookie-consent').click(function(e) {
            e.preventDefault();
        });

        var fixedLinkList = $('.js-fixed-link-list');
        var fixedLinkButton = fixedLinkList.find('.js-toggle-fixed-links');
        var timm = setTimeout(toggleFixedLinks, 2500);

        fixedLinkButton.click(function(e) {
            e.preventDefault();
            toggleFixedLinks();

        });

        function toggleFixedLinks() {
            clearTimeout(timm);
            fixedLinkList.toggleClass('out');
            setTimeout(toggleFixedLinkButton, 50);

            if (fixedLinkList.hasClass('out')) {
                closeFixedNavPopups();
            }
        }

        function toggleFixedLinkButton() {
            fixedLinkButton.toggleClass('out');
        }

        // ------------------------------------------------------
        // owl / content
        // ------------------------------------------------------
        var owlReasons = $('.owl-carousel.owl-reasons');
        if (owlReasons.length > 0) {
            owlReasons.owlCarousel({
                loop: true,
                items: 1,
                dots: true,
                nav: true,
                navText: ['', ''],
                autoHeight : true,
                smartSpeed: 600
            });
        }
    });
}(jQuery));
