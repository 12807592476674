(function($)
{
    $(document).ready(function ()
    {
        // ------------------------------------------------------
        // brochure form
        // ------------------------------------------------------
        var brochureForm = $('.mod_brochure-form');
        if (brochureForm.length > 0) {
            var totalPrice = $('#js-price-total');
            var taxTotalPrice = $('#js-tax-total');
            var taxA = $('#js-tax-a');
            var totalA = $('#js-total-a');
            var taxB = $('#js-tax-b');
            var totalB = $('#js-total-b');
            var quantityInputs = brochureForm.find('.input-quantity');
            var orders = getCurrentOrders();
            var clearBtn = $('#js-clear');
            var submitBtn = $('#js-submit');
            var printBtn = $('#js-print');

            /**
             * if input changes
             */
            quantityInputs.on('input', function()
            {
                orders = getCurrentOrders();

                // sums of all parts
                //var totalPrices = calculateTotal(orders);

                // fill out form fields
                // if (typeof totalPrices !== undefined) {
                //     filloutFormFields(totalPrices);
                // }

                // only send form if enough products are selected
                // if (totalPrices.total >= 25) {
                //     submitBtn.prop("disabled", false);
                // } else {
                //     submitBtn.prop("disabled", true);
                // }

                var els = $('.input-quantity').filter(function() {
                    return this.value !== "" && this.value !== "0" && this.value !== "00" && this.value !== "000";
                });

                if (els.length > 0) {
                    submitBtn.prop("disabled", false);
                } else {
                    submitBtn.prop("disabled", true);
                }
            });

            /**
             * clea 'em all
             */
            clearBtn.click(function onClick (e)
            {
                e.preventDefault();
                quantityInputs.val('');
                quantityInputs.trigger('input');
            });

            printBtn.click(function onClick (e)
            {
                e.preventDefault();
                window.print();
            });
        }

        /**
         * fill out form fields
         *
         * @param totalPrices
         */
        function filloutFormFields(totalPrices)
        {
            //
            // sums and stuff
            //
            var sumRows = totalPrices.totalRows; 
            var sumTotalA = totalPrices.totalA.toFixed(2).replace('.',',');
            var sumTaxA = totalPrices.taxA.toFixed(2).replace('.',',');
            var sumTotalB = totalPrices.totalB.toFixed(2).replace('.',',');
            var sumTaxB = totalPrices.taxB.toFixed(2).replace('.',',');
            var sumTotalAll = totalPrices.total.toFixed(2).replace('.',',');
            var sumTaxTotalAll = totalPrices.taxTotal.toFixed(2).replace('.',',');

            //
            // put values in html
            //
            var i;
            brochureForm.find('.js-total-row').html('0,00');
            for (i = 0; i < sumRows.length; i++) {
                $(brochureForm.find('.js-total-row[data-brochure-itemno="' + sumRows[i].id + '"]')).html(sumRows[i].sum.toFixed(2).replace('.',','));
            }

            // if (typeof sumTotalA !== undefined) {
            //     totalA.html(sumTotalA);
            // }
            //
            // if (typeof sumTaxA !== undefined) {
            //     taxA.html(sumTaxA);
            // }
            //
            // if (typeof sumTotalB !== undefined) {
            //     totalB.html(sumTotalB);
            // }
            //
            // if (typeof sumTaxB !== undefined) {
            //     taxB.html(sumTaxB);
            // }

            // if (typeof sumTotalAll !== undefined) {
            //     totalPrice.html(sumTotalAll);
            // }
            //
            // if (typeof sumTaxTotalAll !== undefined) {
            //     taxTotalPrice.html(sumTaxTotalAll);
            // }
        }

        /**
         * return the current orders (price & amount)
         *
         * @returns {Array}
         */
        function getCurrentOrders()
        {
            var _orders = {a: [], b: []};
            brochureForm.find('.brochure-list-part').each(function()
            {
                var thisListPart = $(this);
                var part = thisListPart.data('list');

                thisListPart.find('.brochure').each(function()
                {
                    var thisRow = $(this);
                    var order = {};
                    // var priceWithDots = thisRow.find('.price').html().replace(',','.');
                    // order.price = parseFloat(priceWithDots);
                    order.quantity = parseInt(thisRow.find('.input-quantity').val());
                    order.itemno = thisRow.data('brochure-itemno');

                    // if (part === 'a') {
                    //     _orders.a.push(order);
                    // } else {
                    //     _orders.b.push(order);
                    // }
                });
            });

            return _orders;
        }

        /**
         * calculates sums out of pretty much all parts of the form
         *
         * @param _orders
         * @returns {{totalA: number, taxA: (number|*), totalB: number, taxB: (number|*), total: number, taxTotal: *}}
         */
        function calculateTotal(_orders)
        {
            var totalRows = [];
            var totalRow;
            var sumA = 0;
            var taxA;
            var sumB = 0;
            var taxB;
            var stepSum = 0;

            // sum part A
            for (var i = 0; i < _orders.a.length; i++) {
                if (_orders.a[i].price && _orders.a[i].quantity) {

                    // sum for this item (row)
                    stepSum = _orders.a[i].price * _orders.a[i].quantity;
                    totalRow = {};
                    totalRow.id = orders.a[i].itemno;
                    totalRow.sum = stepSum;
                    totalRows.push(totalRow);

                    // sum for total A
                    sumA += stepSum;
                }
            }

            // tax part A
            taxA = sumA / 100 * 7;

            // sum part B
            for (var j = 0; j < _orders.b.length; j++) {
                if (_orders.b[j].price && _orders.b[j].quantity) {

                    // sum for this item (row)
                    stepSum = _orders.b[j].price * _orders.b[j].quantity;
                    totalRow = {};
                    totalRow.id = orders.b[j].itemno;
                    totalRow.sum = stepSum;
                    totalRows.push(totalRow);

                    // sum for total B
                    sumB += stepSum;
                }
            }

            // tax part B
            taxB = sumB / 100 * 19;

            return {
                totalRows: totalRows,
                totalA:     sumA,
                taxA:       taxA,
                totalB:     sumB,
                taxB:       taxB,
                total:      (sumA + sumB),
                taxTotal:   (taxA + taxB)
            };
        }
    });
}(jQuery));